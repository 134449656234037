import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  title:'';
  latestTitle:'';
  cancel: 'Cancel';
  ok: 'OK';
  type:string;
  inputField:'';
  inputValue:'';
  passwordField:'';
  password:'';
  newPasswordField:'';
  newPassword:'';
  confirmPasswordField:'';
  confirmPassword:'';
  userIdField: '';
  userId: '';
  emailField:'';
  email:'';
  securityQuestionField:'';
  securityQuestion:'';
  changeSecurityQuestion:false;
  securityQuestionAnswerField:'';
  securityQuestionAnswer:'';
  emailFieldDisabled:string;
  style: '';
  counterDownInitValue:0;
  counterDownInterval:0;
  counterDownIntervalUnit:'sec';
  message:'';
}

@Component({
  selector: 'component-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css']
})

export class CustomDialogComponent implements OnInit {
  title = '';
  latestTitle = '';

  constructor(public dialogRef: MatDialogRef<CustomDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    let title = '';
    if (this.dialogData.type == 'warning-dialog-update') {
      this.latestTitle = this.dialogData.title;
    } else {
       title = this.dialogData.title;
    }
    if (this.dialogData.type == 'confirm-dialog-countdown') {
      const interval = this.dialogData.counterDownIntervalUnit == 'sec'?1000:60000;
      let counterDown = this.dialogData.counterDownInitValue;
      this.title = title.replace('<CounterDown>',counterDown.toString()) ;

      setInterval(() => {
        if (counterDown < 2) {
          clearInterval(0);
          this.close();
        }else {
          counterDown--;
          this.title = title.replace('<CounterDown>',counterDown.toString()) ;
        }
      }, interval);
    } else {
      this.title = title;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
