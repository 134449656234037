import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from '../../../../common/environments/environment';
import {CommonService} from '../../../../common/services/common.service';
import {HttpEvent} from '@angular/common/http';
import { getLocale } from 'apps/common/helper/util-function-class';

@Injectable({
  providedIn: 'root'
})

export class IcaaService {
  umaApiUrl = environment.umaApiUrl;
  icaaApiUrl = environment.icaaApiUrl;
  icpaaApiUrl = environment.icpaaApiUrl;
  lookupApiUrl = environment.lookupApiUrl;
  epaApiUrl = environment.epaApiUrl;
  fcraApiUrl = environment.fcraApiUrl;
  amtApiUrl = environment.amtApiUrl;
  emisApiUrl = environment.emisApiUrl;
  private lookupAdminApiUrl = environment.lookupAdminApiUrl;

  constructor(private commonService: CommonService) { }

  getUserProfile(userId:string, callback, errorCallback) {
    this.commonService.getApi(this.umaApiUrl + '/getUserProfile/' + userId, callback, errorCallback);
  }

  getCompanyInfo(personId, stationId, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getCompanyInfo/' + personId + '/' + stationId, callBack, errorCallback);
  }

  saveCompanyInfo(companyInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveCompanyInfo', companyInfo, callBack, errorCallback);
  }

  getCompanyStaffList(personId, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getCompanyStaffList/'+personId, callBack, errorCallback);
  }

  saveCompanyStaffInfo(companyStaffInfo: any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveCompanyStaffInfo', companyStaffInfo, callback, errorCallback);
  }

  deleteCompanyStaffInfo(companyStaffInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/deleteCompanyStaffInfo', companyStaffInfo, callBack, errorCallback);
  }

  getCompanyOffence(personId, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getCompanyOffence/'+personId, callBack, errorCallBack);
  }

  deleteCompanyOffence(offenceDetails: any, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/deleteCompanyOffence', offenceDetails, callBack, errorCallBack);
  }

  saveCompanyOffence(offenceDetails: any, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/saveCompanyOffence', offenceDetails, callBack, errorCallBack);
  }

  saveCompanyEligibility(eligibilityDtls: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveCompanyStaffInfo', eligibilityDtls, callBack, errorCallback);
  }

  getInspectionCentreList(personId: any, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getInspectionCentreList/" + personId + "/" + userId, callBack, errorCallBack);
  }

  searchInspectionCenters(searchBy:string, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/findInspectionCentreList?searchBy=' + searchBy, callBack, errorCallback);
  }

  searchInspectionCentreList(vicSearch:any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/searchInspectionCentreList', vicSearch, callBack, errorCallback);
  }

  getInspectionCentreInfo(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getInspectionCentreInfo/" + stationId, callBack, errorCallBack);
  }

  getVicInfo(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getVicInfo/" + stationId, callBack, errorCallBack);
  }

  getStationInfo(stationId: string, companyId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl +  "/" + getLocale() + "/getStationInfo/" + stationId + '/' + companyId, callBack, errorCallBack);
  }

  getInspectionCentreStepStatus(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getInspectionCentreStepStatus/" + stationId, callBack, errorCallBack);
  }

  saveInspectionCentre(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveInspectionCentre', inspectionCentreInfo, callBack, errorCallback);
  }

  getStationInsuranceInfo(userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationInsuranceInfo/" + userId, callBack, errorCallBack);
  }

  saveStationInsurance(stationInsuranceInfoResult: any, aUserId, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationInsurance/' + aUserId, stationInsuranceInfoResult, callBack, errorCallback);
  }

  saveTaxVerfication(stationInsuranceInfoResult: any, aUserId, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveTaxVerfication/' + aUserId, stationInsuranceInfoResult, callBack, errorCallback);
  }

  getStationDelegates(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationDelegates/'+stationId, callBack, errorCallBack);
  }

  getStationDelegateByEmail(email:string, callback, errorCallback ) {
    this.commonService.getApi(this.umaApiUrl + '/getStationDelegateByEmail?email=' + email, callback, errorCallback);
  }

  removeStationDelegate(updateUser: String, aStationId: string, aUserId: any, callback, errorCallback ) {
    const aStationDelegate = {stationId:aStationId, userId:aUserId};
    this.commonService.postApi(this.icaaApiUrl + '/removeStationDelegate/' + updateUser, aStationDelegate, callback, errorCallback);
  }

  createStationDelegate(createUser: String, aStationDelegate: any, callback, errorCallback ) {
    this.commonService.postApi(this.icaaApiUrl+'/createStationDelegate/'+createUser, aStationDelegate,  callback, errorCallback);
  }

  saveStationDelegate(createUser: String, aStationDelegate: any, callback, errorCallback ) {
    this.commonService.postApi(this.icaaApiUrl+'/saveStationDelegate/'+createUser, aStationDelegate, callback, errorCallback);
  }

  updateStationDelegate(updateUser: String, aStationDelegate: any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationDelegate/' + updateUser, aStationDelegate, callback, errorCallback);
  }

  getStationBankAccountInfo(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationBankAccountInfo/" + stationId, callBack, errorCallBack);
  }

  saveStationBankInfo(bankInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationBankInfo', bankInfo, callBack, errorCallback);
  }

  getStationDocuments(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationDocuments/" + stationId, callBack, errorCallBack);
  }

  downloadStationContent(stationContentId: string): Observable<any>  {
    return this.commonService.downloadFile(this.icaaApiUrl  + "/downloadStationContent/" + stationContentId);
  }

  downloadCompanyContent(companyContentId: string): Observable<any>  {
    return this.commonService.downloadFile(this.icaaApiUrl  + "/downloadCompanyContent/" + companyContentId);
  }

  uploadStationMBLDoc(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadStationMBLDoc/', formData);
  }

  uploadStationDoc(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadFile/', formData);
  }
  uploadCompanyChangeReqFile(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadCompanyChangeReqFile/', formData);
  }

  deleteDocument(stationContentId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/deleteDocument/' + stationContentId, aCallback, anErrorCallback);
  }

  getOwnerOnboardingStatus(personId: string, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getOwnerOnboardingStatus/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getInspectionCentreStatus(personId: string, userId: string, stationId: string, callBack, errorCallBack){
    this.commonService.getApi(this.icaaApiUrl  + "/getInspectionCentreStatus/" + personId + "/" + userId + '/' + stationId, callBack, errorCallBack);
  }

  getStationContract(stationId: string, contractTypeCd: string, isChangeReq: boolean,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationContract/" + stationId + "/" + contractTypeCd + "/" + isChangeReq, callBack, errorCallBack);
  }

  getStationContracts(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationContracts/" + stationId, callBack, errorCallBack);
  }

  getStationReContracts(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationReContracts/" + stationId, callBack, errorCallBack);
  }

  saveContract(stationPerformanceContract: any, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/' + getLocale() + '/saveContract/', stationPerformanceContract, callback, errorCallBack);
  }

  saveContractToS3Bucket(stationPerformanceContract: any, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/saveContractToS3Bucket/', stationPerformanceContract, callback, errorCallBack);
  }

  directorRejectContract(stationPerformanceContract: any, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/directorRejectContract/', stationPerformanceContract, callback, errorCallBack);
  }

  sendOwnerContractSignedEmail(stationId, ownerUserId, stationTestClassId, callback, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/sendOwnerContractSignedEmail/' + stationId + '/' + ownerUserId + '/' + stationTestClassId, callback, errorCallBack);
  }

  downContractPdf(stationPerformanceContract: any, contractTypeCd: string): Observable<any> {
    return this.commonService.downloadFilePost(this.icaaApiUrl + '/downContractPdf/'+ contractTypeCd, stationPerformanceContract);
  }

  downFleetContractPdf(stationPerformanceContract: any): Observable<any> {
    return this.commonService.downloadFilePost(this.icaaApiUrl + '/downFleetContractPdf/', stationPerformanceContract);
  }

  downPadContractPdf(stationPerformanceContract: any): Observable<any> {
    return this.commonService.downloadFilePost(this.icaaApiUrl + '/downPadContractPdf/', stationPerformanceContract);
  }

  getStationInsuranceDocument(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getStationInsuranceDocument/" + stationId, callBack, errorCallBack);
  }

  getStationMblDocument(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getStationMblDocument/" + stationId, callBack, errorCallBack);
  }

  getCompanyDocument(companyId: string, docTypeId, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getCompanyDocument/" + companyId + "/" + docTypeId, callBack, errorCallBack);
  }

  getStationDocumentByType(stationId: string, documentTypeId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getStationDocumentByType/" + stationId + "/" + documentTypeId, callBack, errorCallBack);
  }

  getStationPadInfo(personId: number, stationId: string, callback, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/' + getLocale() + '/getStationPadInfo/'+ personId +'/'+ stationId, callback, errorCallBack);
  }

  getStationReadiness(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationReadiness/'+ stationId, callBack, errorCallBack);
  }

  getStationTechnicians(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationTechnicians/'+ stationId, callBack, errorCallBack);
  }

  getStationTechniciansWithStatus(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationTechniciansWithStatus/'+ stationId, callBack, errorCallBack);
  }

  saveOwnerReadinessStatus(readinessInfo: any, userId: string, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveOwnerReadinessStatus' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  submitOwnerReadinessInfo(readinessInfo: any, userId: string, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/submitOwnerReadinessInfo' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  sendEmailToParsonsUsers(userId: string, readinessInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/sendEmailToParsonsUsers' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  sendEmailToOwner(userId: string, readinessInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/sendEmailToOwner' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  saveParsonsReadinessStatus(readinessInfo: any, userId: string, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveParsonsReadinessStatus' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  readinessRejected(readinessInfo: any, userId: string, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/readinessRejected' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  readinessApproved(readinessInfo: any, userId: string, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/readinessApproved' + '/' + userId, readinessInfo, callBack, errorCallback);
  }

  ministryUserApprovedApplication(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/ministryUserApprovedApplication' + '/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  ministryUserRequestForChange(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/ministryUserRequestForChange' + '/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  sendOwnerRequestedChangeEmail(contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/sendOwnerRequestedChangeEmail', contractReviewApiResult, callBack, errorCallback);
  }

  ministryUserCanelApplication(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/ministryUserCanelApplication' + '/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  sendOwnerApplicationCancelEmail(contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/sendOwnerApplicationCancelEmail', contractReviewApiResult, callBack, errorCallback);
  }

  getOrderDetailsForICAA(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.epaApiUrl + '/getOrderDetailsForICAA/'+ stationId, callBack, errorCallBack);
  }

  getStationReviewData(stationId, userType, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationReviewData/'+ stationId + '/' + userType, callBack, errorCallBack);
  }

  getParsonsReviewData(stationId, userType, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getParsonsReviewData/'+ stationId + '/' + userType, callBack, errorCallBack);
  }

  sendDirectorApproveEmailToOwnerDelegate(stationId, ownerUserId, callback, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/sendDirectorApproveEmailToOwnerDelegate/' + stationId + '/' + ownerUserId, callback, errorCallBack);
  }

  sendDirectorRejectEmailToMinistryUser(stationId, ownerUserId, stationTestClassId, callback, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/sendDirectorRejectEmailToMinistryUser/' + stationId + '/' + ownerUserId + '/' + stationTestClassId, callback, errorCallBack);
  }

  sendEmailToDirector(contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/sendEmailToDirector', contractReviewApiResult, callBack, errorCallback);
  }

  public lookup(lookObj:string, callBack) {
    this.commonService.getApi(this.icaaApiUrl + '/get/' + lookObj, callBack, this.commonService.errorCallback);
  }

  getStationTechnicianAnomaly(personId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationTechnicianAnomaly/" + personId, callBack, errorCallBack);
  }

  updateStationStatusCode(stationId: string, stationStatusCd: string, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/updateStationStatusCode/" + stationId + "/" + stationStatusCd + "/" + userId, callBack, errorCallBack);
  }

  updateUserProfile(userid: any, data: any, callback, errorCallback) {
    this.commonService.postApi(this.umaApiUrl + '/updateUserProfile/' + userid, data, callback, errorCallback);
  }
  saveStationBusHoursChgReq(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationBusHoursChgReq', inspectionCentreInfo, callBack, errorCallback);
  }

  saveStationBusNameChgReq(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationBusNameChgReq', inspectionCentreInfo, callBack, errorCallback);
  }

  saveStationTestClassChgReq(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationTestClassChgReq', inspectionCentreInfo, callBack, errorCallback);
  }

  saveStationReContractingChgReq(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationReContractingChgReq', inspectionCentreInfo, callBack, errorCallback);
  }

  saveStationChangeOwnershipChgReq(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationChangeOwnershipChgReq', inspectionCentreInfo, callBack, errorCallback);
  }

  saveStationAddrPhoneChgReq(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationAddrPhoneChgReq', inspectionCentreInfo, callBack, errorCallback);
  }

  getOpenInspectionCentreList(personId: any, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getOpenInspectionCentreList/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getVICList(personId: any, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getVICList/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getDelegateVICList(userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getDelegateVICList/" + userId, callBack, errorCallBack);
  }

  saveStationChgRequest(inspectionCentreInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationChgRequest', inspectionCentreInfo, callBack, errorCallback);
  }

  uploadStationChangeReqFile(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadStationChangeReqFile/', formData);
  }

  updateStationChangeReqFile(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/updateStationChangeReqFile/', formData);
  }

  getStationChgReqDocumentByType(stationId: string, changeRequestIds: any, documentTypeId: string,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getStationChgReqDocumentByType/" + stationId + "/" + changeRequestIds + "/" + documentTypeId , callBack, errorCallBack);
  }

  getStationChgReqMBLDocument(stationId: string, displaySubstatusId: any, documentTypeId: string,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getStationChgReqMBLDocument/" + stationId + "/" + displaySubstatusId + "/" + documentTypeId , callBack, errorCallBack);
  }

  downloadStationChgReqContent(stationContentId: string): Observable<any>  {
    return this.commonService.downloadFile(this.icaaApiUrl  + "/downloadStationChgReqContent/" + stationContentId);
  }

  getPendingRequestDetailList(personId, stationId, changeRequestIds:string, displaySubStatusId:string, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getPendingRequestDetailList/' + personId + '/' + stationId + '/' + changeRequestIds + "/" + displaySubStatusId, callBack, errorCallback);
  }

  getStationChgReqContracts(stationId: string, stationTestClassId: any, changeRequestIds: any,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationChgReqContracts/" + stationId + "/" + stationTestClassId + "/" + changeRequestIds, callBack, errorCallBack);
  }

  saveChangeRequest(vicPendingDetailResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveChangeRequest', vicPendingDetailResult, callBack, errorCallback);
  }

  saveVicInsuranceDtlsChgReq(inspectionCentre: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveVicInsuranceDtlsChgReq', inspectionCentre, callBack, errorCallback);
  }

  saveAndSubmitMobileCapableChgReq(inspectionCentre: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveAndSubmitMobileCapableChgReq', inspectionCentre, callBack, errorCallback);
  }

  getCompanyPendingRequestDetail(personId, stationId, changeRequestIds, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getCompanyPendingRequestDetail/' + personId + '/' + stationId + '/' + changeRequestIds, callBack, errorCallback);
  }

  saveChgReqContract(stationPerformanceContract: any, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/' + getLocale() + '/saveChgReqContract', stationPerformanceContract, callback, errorCallBack);
  }

  getStationChgPadPadInfo(personId: number, stationId: string, changeRequestIds: string, callback, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/' + getLocale() + '/getStationChgPadPadInfo/' + personId  + '/'+ stationId + '/' + changeRequestIds, callback, errorCallBack);
  }

  submitChangeRequest(stationId: any, changeRequest: any, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/submitChangeRequest/' + stationId, changeRequest, callBack, errorCallBack);
  }

  getStationChgReqBankAccountInfo(stationId: string, isChangeReq: boolean, changeRequestIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/' + getLocale() + "/getStationChgReqBankAccountInfo/" + stationId + "/" + isChangeReq + "/" + changeRequestIds, callBack, errorCallBack);
  }

  searchChangeRequestVICList(searchCriteria:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/searchChangeRequestVICList', searchCriteria, aCallback, anErrorCallback);
  }

  getStationChgReqReviewData(displaySubstatusId: any, stationId, changeRequestIds: String, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationChgReqReviewData/'+ displaySubstatusId + "/" + stationId + '/' + changeRequestIds, callBack, errorCallBack);
  }

  ministryUserApprovedChgReqApplication(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/ministryUserApprovedChgReqApplication/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  mtoUserApprovedChgReqList(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/mtoUserApprovedChgReqList/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  mtoCancelChgReqApplication(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/mtoCancelChgReqApplication/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  mtoCancelChgReqApplicationList(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/mtoCancelChgReqApplicationList/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  mtoRequestToChangeChgReqApplication(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/mtoRequestToChangeChgReqApplication/' + userId, contractReviewApiResult, callBack, errorCallback);
  }


  mtoUserReqForChangeChgReqList(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/mtoUserReqForChangeChgReqList/' + userId, contractReviewApiResult, callBack, errorCallback);
  }


  directorApprovedChangeRequest(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/directorApprovedChangeRequest/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  directorRejectedChangeRequest(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/directorRejectedChangeRequest/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  directorApprovedChangeRequestList(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/directorApprovedChangeRequestList/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  directorApprovedWithdrawChangeRequest(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/directorApprovedWithdrawChangeRequest/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  directorRejectedChangeRequestList(userId: string, contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/directorRejectedChangeRequest/' + userId, contractReviewApiResult, callBack, errorCallback);
  }

  getStationChgReqContract(stationId: string, contractTypeCd: string, changeRequestIds: String, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationChgReqContract/" + stationId + "/" + contractTypeCd + "/" + changeRequestIds, callBack, errorCallBack);
  }

  getApprovedVicListByCompId(companyId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getApprovedVicListByCompId/' + companyId, callBack, errorCallBack);
  }

  getVicTestTypes(callback, errorCallback) {
    this.commonService.getApi(this.lookupApiUrl + '/getVicTestTypes', callback, errorCallback);
  }

  getStationTestTypesAndMobileYn(callback, errorCallback) {
    this.commonService.getApi(this.lookupApiUrl + '/getStationTestTypesAndMobileYn/en', callback, errorCallback);
  }

  submitStationSafetyToolInfo(contractReadiness: any, userId: string, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/submitStationSafetyToolInfo/' + userId, contractReadiness, callback, errorCallBack);
  }

  saveStationSafetyToolInfo(contractReadiness: any, userId: string, callback, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/saveStationSafetyToolInfo/' + userId, contractReadiness, callback, errorCallBack);
  }

  getStationSafetyToolInfo(stationId, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationSafetyToolInfo/' + stationId, callBack, errorCallBack);
  }

  uploadMessageInfoFile(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadMessageInfoFile/', formData);
  }

  getMessageInfoDocuments(messageInfoId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getMessageInfoDocuments/" + messageInfoId, callBack, errorCallBack);
  }


  deleteMessageInfoDocument(messageInfoId:string, messageDocId: string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/deleteMessageInfoDocument/' + messageInfoId + "/" + messageDocId, aCallback, anErrorCallback);
  }

  downloadMessageDoc(messageInfoId: string, messageDocId): Observable<any>  {
    return this.commonService.downloadFile(this.icaaApiUrl  + "/downloadMessageDoc/" + messageInfoId + "/" + messageDocId);
  }

  searchMessageInfoList(vicSearch:any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/searchMessageInfoList', vicSearch, callBack, errorCallback);
  }

  saveMessageInfo(messageInfo: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveMessageInfo', messageInfo, callBack, errorCallback);
  }

  cancelMessageInfo(messageInfoId: string, messageRequestTypeId: number, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/cancelMessageInfo/" + messageInfoId + "/" + messageRequestTypeId, callBack, errorCallBack);
  }

  getMessageInfoDetails(messageInfoId: string, messageRequestTypeId: number, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getMessageInfoDetails/" + messageInfoId + "/" + messageRequestTypeId, callBack, errorCallBack);
  }

  addMessageStation(messageStationTabletXref: any, userId: string, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/addMessageStation/' + userId, messageStationTabletXref, callBack, errorCallBack);
  }

  deleteMessageStation(messageInfoId: any, messageScheduledToStationId: any, stationId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/deleteMessageStation/' + messageInfoId + "/" +  messageScheduledToStationId + "/" + stationId , callBack, errorCallBack);
  }

  getOwnerTechnicians(personId:any, userId: any, callBack, errorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/getOwnerTechnicians/' + personId + "/" + userId, callBack, errorCallback);
  }

  addMessageTechnician(messageTechnicianXref: any, userId: string, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + '/addMessageTechnician/' + userId, messageTechnicianXref, callBack, errorCallBack);
  }

  deleteMessageTechnician(messageInfoId: any, messageScheduledToTechnicianId: any, techPersonId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + '/deleteMessageTechnician/' + messageInfoId + "/" + messageScheduledToTechnicianId + "/" + techPersonId , callBack, errorCallBack);
  }

  getUserProfileByPersonId(personId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.umaApiUrl + '/getUserProfileByPersonId/' + personId, aCallback, anErrorCallback);
  }

  getTechnicianDetails(personId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getTechnicianDetails/' + personId, aCallback, anErrorCallback);
  }

  getTrainingStatus(techId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getTrainingStatus/" + techId, callBack, errorCallBack);
  }

  submitStationWithdrawChgReq(changeRequest: any, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + "/" + getLocale() + '/submitStationWithdrawChgReq', changeRequest, callBack, errorCallBack);
  }

  isVicHasErrorCollectingPad(stationId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.fcraApiUrl + '/isVicHasErrorCollectingPad/' + stationId, aCallback, anErrorCallback);
  }

  getWithdrawPendingRequestDetailList(companyId: string, stationId:string, changeRequestId: string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/' + getLocale() + '/getWithdrawPendingRequestDetailList/' + companyId + '/' + stationId + '/' + changeRequestId, aCallback, anErrorCallback);
  }

  acknowledgeAudit(auditId:string, userId:string, callBack, errorCallBack) {
    this.commonService.postApi(this.amtApiUrl + "/acknowledgeAudit/" + auditId + "/" + userId, null, callBack, errorCallBack);
  }

  getStationChgReqWithdrawContract(stationId: string, changeRequestIds: any,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationChgReqWithdrawContract/" + stationId + "/" + changeRequestIds, callBack, errorCallBack);
  }

  updateOfflineLimit(userId:string, visUnitDTO: any, callBack, errorCallBack) {
    this.commonService.postApi(this.emisApiUrl + "/updateOfflineLimit/" + userId, visUnitDTO, callBack, errorCallBack);
  }

  submitStationDormancyRequest(userId:string, stationSchedLockoutDTO: any, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl + "/submitStationDormancyRequest/" + userId, stationSchedLockoutDTO, callBack, errorCallBack);
  }

  getStationDormancyRequestList(stationId: string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + "/getStationDormancyRequestList/" + stationId, aCallback, anErrorCallback);
  }

  deleteStationDormancyRequest(userId: string, stationSchedLockoutDTO: any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.icaaApiUrl + "/deleteStationDormancyRequest/" + userId, stationSchedLockoutDTO, aCallback, anErrorCallback);
  }

  getReContractDocument(contractId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/getReContractDocument/" + contractId, callBack, errorCallBack);
  }

  searchReContractInfoList(locale: string, vicSearch:any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/searchReContractInfoList/' + locale, vicSearch, callBack, errorCallback);
  }

  saveVicReContract(contractDTO: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/saveVicReContract/' + getLocale(), contractDTO, callBack, errorCallback);
  }

  verifyRecontractExists(contractDTO: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/verifyRecontractExists/' + getLocale(), contractDTO, callBack, errorCallback);
  }

  deleteVicReContract(contractDTO: any, callBack, errorCallBack) {
    this.commonService.postApi(this.icaaApiUrl  + "/deleteVicReContract/" + getLocale(), contractDTO, callBack, errorCallBack);
  }

  getVicContractInfoByReContractId(stationTestClassRecontractXrefId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getVicContractInfoByReContractId/" + stationTestClassRecontractXrefId, callBack, errorCallBack);
  }

  uploadVicExcludeDocFile(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadVicExcludeDocFile/', formData);
  }

  getVicExcludeDocFileLocation(contractId: number, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getVicExcludeDocFileLocation/" + contractId, callBack, errorCallBack);
  }

  downloadContractDoc(contentId: string): Observable<any>  {
    return this.commonService.downloadFile(this.icaaApiUrl  + "/downloadContractDoc/" + contentId);
  }

  uploadContractDocFile(formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.icaaApiUrl + '/uploadContractDocFile/', formData);
  }

  getContractDocFileLocation(contractId: number,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getContractDocFileLocation/" + contractId, callBack, errorCallBack);
  }

  downloadVicExcludeDoc(contentId: string): Observable<any>  {
    return this.commonService.downloadFile(this.icaaApiUrl  + "/downloadVicExcludeDoc/" + contentId);
  }

  getVicOwnerReContractList(personId: string, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getVicOwnerReContractList/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getStationContractByTypeCd(stationId: string, contractTypeCd: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationContractByTypeCd/" + stationId + "/" + contractTypeCd, callBack, errorCallBack);
  }

  getTabletEquipmentRebateDates(callBack, errorCallBack) {
    this.commonService.getApi(this.epaApiUrl + "/getTabletEquipmentRebate", callBack, errorCallBack);
  }

  getStoTradeCodes(callBack, errorCallBack) {
    this.commonService.getApi(this.lookupApiUrl  + "/getStoTradeCodes", callBack, errorCallBack);
  }

  getStationIdsByOwner(personId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationIdsByOwner/" + personId, callBack, errorCallBack);
  }

  getTechnicianIdsByOwner(personId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getTechnicianIdsByOwner/" + personId, callBack, errorCallBack);
  }

  getStationLockouts(stationIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getStationLockoutsByStationIds/" + stationIds, callBack, errorCallBack);
  }
  getStationSuspensions(stationIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.amtApiUrl  + "/getStationSuspensions/" + stationIds, callBack, errorCallBack);
  }

  getTechnicianLockouts(technicianIds: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getTechnicianLockoutsByTechnicianIds/" + technicianIds, callBack, errorCallBack);
  }

  getTechnicianRetrainings(technicianIds:string, callBack, errorCallBack) {
    this.commonService.getApi(this.amtApiUrl  + "/getTechnicianRetrainings/" + technicianIds, callBack, errorCallBack);
  }

  getPersonCertification(personId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getPersonCertificationFull/" + personId, callBack, errorCallBack);
  }

  getMembershipInfo(personId: any, callBack, errorCallBack) {
    this.commonService.getApi(this.icpaaApiUrl  + "/getMembershipInfo/" + personId, callBack, errorCallBack);
  }

  sendCrEmailToDirector(contractReviewApiResult: any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/sendCrEmailToDirector', contractReviewApiResult, callBack, errorCallback);
  }

  getStationBankPadAgreements(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/getStationBankPadAgreements/" + stationId, callBack, errorCallBack);
  }

  initializeVicBankPadAgreement(stationId: string, stationTestClassId: string,  callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl + "/" + getLocale() + "/initializeVicBankPadAgreement" + "/" + stationId + "/" + stationTestClassId, callBack, errorCallBack);
  }

  getVicSearchStatusList(callBack, errorCallBack) {
    this.commonService.getApi(this.lookupApiUrl + "/getVicSearchStatusList/" + getLocale(), callBack, errorCallBack);
  }

  getSimpleInspectionCentreInfo(stationId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl +  "/getSimpleInspectionCentreInfo/" + stationId, callBack, errorCallBack);
  }
  getOwnerApprovedVicList(personId: any, userId: string, callBack, errorCallBack) {
    this.commonService.getApi(this.icaaApiUrl  + "/getOwnerApprovedVicList/" + personId + "/" + userId, callBack, errorCallBack);
  }

  getVicPendingRequestDetail(personId, stationId, changeRequestIds:string, displaySubStatusId:string, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getVicPendingRequestDetail/' + personId + '/' + stationId + '/' + changeRequestIds + "/" + displaySubStatusId, callBack, errorCallback);
  }
  searchVicList(vicSearch:any, callBack, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/searchVicList', vicSearch, callBack, errorCallback);
  }

  isMvisIdExist(mvisId: string, stationId: string, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/isMvisIdExist?mvisId=' + mvisId + "&stationId=" + stationId, callback, errorCallback);
  }

  getMvisIdExistVicList(mvisId: string, stationId: string, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getMvisIdExistVicList?mvisId=' + mvisId + "&stationId=" + stationId, callback, errorCallback);
  }

  public getLookupObjById(lookObj:string, id:string, callback) {
    this.commonService.getApi(this.icaaApiUrl + '/get/' + lookObj + '/' + id, callback, this.commonService.errorCallback);
  }

  getLegacyMvisIdVic(station: any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/getLegacyMvisIdVic',  station, callback, errorCallback);
  }

  getLegacyMvisIdVicList(station: any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/getLegacyMvisIdVicList',  station, callback, errorCallback);
  }

  getInspectionCentreListToReview(includeReviewed, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getInspectionCentreListToReview/' + includeReviewed, callback, errorCallback);
  }

  getStationContentBase64(stationId: string, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationContentBase64/' + stationId, callback, errorCallback);
  }

  updateReviewStatus(stationId: string, status: boolean, userId: string, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/updateReviewStatus/' + stationId + "/" + status + "/" + userId, null, callback, errorCallback);
  }

  public isOnboardingValid(callback) {
    this.commonService.getApi(this.lookupAdminApiUrl + '/isOnboardingValid', callback, this.commonService.errorCallback);
  }
  
  ignoreMTOApprovalFlag(stationid: string, callBack, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/ignoreMTOApprovalFlag/' + stationid, callBack, errorCallback);
  }

}
