import { Component, OnInit } from '@angular/core';
import { IcaaService } from '../../../services/icaa.service';

import { ActivatedRoute, Router } from '@angular/router';
import { MainNavComponent } from 'apps/common/main-nav/main-nav.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../../../../../common/services/common.service';
import { isBlank } from 'apps/common/helper/util-function-class';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'onboarding-steps',
  templateUrl: './onboarding-steps.component.html',
  styleUrls: ['./onboarding-steps.component.css']
})
export class OnboardingStepsComponent implements OnInit {
  onboardingSteps: any = [];
  userId: string = null;
  personId: string;
  stationId: string;
  isBlank = isBlank;
  welcomeMessage = $localize`Welcome to the Vehicle Inspection Centre Enrolment.`;
  setExtraStepTitle = $localize`(Not required for Government/Police VICs)`;
  step1Name = $localize`Step 1.`;
  step2Name = $localize`Step 2.`;
  step3Name = $localize`Step 3.`;
  step4Name = $localize`Step 4.`;
  step5Name = $localize`Step 5.`;
  step6Name = $localize`Step 6.`;
  step7Name = $localize`Step 7.`;
  step8Name = $localize`Step 8.`;
  step9Name = $localize`Step 9.`;
  step10Name = $localize`Step 10.`;
  step11Name = $localize`Step 11.`;

  step1Title = $localize`Step 1. Owner Information`;
  step2Title = $localize`Step 2. Business Information`;
  step3Title = $localize`Step 3. Inspection Centre Owner Delegate`;
  step4Title = $localize`Step 4. Insurance Information`;
  step5Title = $localize`Step 5. Tax Compliance Verification`;
  step6Title = $localize`Step 6. Pre-Authorized Debit (PAD) Agreement and Bank Information`;
  step7Title = $localize`Step 7. Performance Contract`;
  step8Title = $localize`Step 8. MTO Performance Contract Approval`;
  step9Title = $localize`Step 9. Readiness Checklist`;
  step10Title = $localize`Step 10. Equipment Purchase Maintenance Agreement and Equipment Purchase`;
  step11Title = $localize`Step 11. Add Technician(s)`;
  
  constructor(public service: IcaaService, public commonService: CommonService, private spinnerService: NgxSpinnerService, private mainNavComponent: MainNavComponent, private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => this.hideMenu(), 50);
    this.userId = this.commonService.getUserId();
    this.personId = this.commonService.getPersonId().toString();
    this.commonService.vicInfo$.subscribe(res => {
      if(res != null) {
        let vicInfo: any = {};
        vicInfo = res;
        let stationIdTemp :any = vicInfo.stationId;
        if(!isBlank(stationIdTemp) && stationIdTemp !== '0' && stationIdTemp !== 0) {
          this.stationId = stationIdTemp;
          localStorage.setItem('stationId', this.stationId);
        }
      }
    });
    
    if(!this.stationId || isBlank(this.stationId) || this.stationId == "0") {
      this.stationId = localStorage.getItem("stationId");
    }

    let queryParams = {stationId: this.stationId};
    this.router.navigate([],
    {
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  
    //this.stationId = this.route.snapshot.queryParamMap.get('stationId');
    if (!this.stationId || isBlank(this.stationId) || this.stationId == "0") {
      console.log(" no station ");
      this.stationId = "0";
    }
    if(!isBlank(this.stationId) && this.stationId !== '0') {
      this.welcomeMessage =  $localize`Welcome to the Vehicle Inspection Centre ` + this.stationId + ` Enrolment`;
    }
    this.spinnerService.show();
    this.service.getInspectionCentreStatus(this.personId, this.userId, this.stationId, this.getStationStatusCallback, this.showErrorMsg);
  }

  hideMenu() {
    this.mainNavComponent.isShowScrollMenu = false;
  }

  getStationStatusCallback = (result: any) => {
    this.spinnerService.hide();
    if (result && result.returnCode >= 0) {
      this.onboardingSteps = result.onboardingStepStatusList;
    } else {
      console.log("There is no onboardingSteps: ");
    }
  }

  navigateToOnboardingStep(step) {    
    this.commonService.changeVicInfo(this.stationId);
    let vicInfo: any = {};
    vicInfo.stepNum = step.stepNum;
    vicInfo.stationId = this.stationId;
    vicInfo.reqFromUrl = "onboardingsteps";
    this.commonService.changeVicInfo(vicInfo);
    let url = step.routerLink+"/1?tabNum=1&stationId="+this.stationId+"&stepNum="+step.stepNum;
    //let url = step.routerLink+"/1?tabNum=1";
    this.router.navigateByUrl(url);
  }

  cancel() {
    this.commonService.goToHomePage();
  }

  translateStepTitle(row) {
    if(row.stepNum == 1) {
      return this.step1Title;
    } else if(row.stepNum == 2) {
      return this.step2Title;
    } else if(row.stepNum == 3) {
      return this.step3Title;
    } else if(row.stepNum == 4) {
      return this.step4Title;
    } else if(row.stepNum == 5) {
      return this.step5Title;
    } else if(row.stepNum == 6) {
      return this.step6Title;
    } else if(row.stepNum == 7) {
      return this.step7Title;
    } else if(row.stepNum == 8) {
      return this.step8Title;
    } else if(row.stepNum == 9) {
      return this.step9Title;
    } else if(row.stepNum == 10) {
      return this.step10Title;
    } else if(row.stepNum == 11) {
      return this.step11Title;
    }
  }

  playVideo() {
    window.open(window.location.protocol+"//"+window.location.host+"/connect/parsonsvic/VICEnrolment.mp4", '_blank');
    return false;
  }

  openFile() {
    let fileName = '240405_DriveON_Phase2_3a_PEP_v2_11_Section5.pdf';
    if(!isBlank(fileName)) {
      window.open(window.location.protocol + "//" + window.location.host + "/ONPublicWeb/" + fileName, '_blank');      
    }
    return false;
  }

  showErrorMsg = (error: any) => {
    this.spinnerService.hide();
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    } else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    } else if (error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }
}
