<a class="skip-main" routerLink="" (click)="skipToMain()">Skip to Main Content </a>
<mat-sidenav-container *ngIf="showMainNav" class="sidenav-container">
  <!-- left side nav -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="'navigation'" [mode]="'over'"
    [opened]="false">
    <br>
    <mat-toolbar style="z-index: 999;background-color:#1b3349 !important; color:#ffffff">&nbsp;
      <button type="button" aria-label="Main Menu" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side Menu navigate icon" style="transform: scale(1.1,1.1);">close</mat-icon>
      </button>&nbsp;
      <span i18n aria-hidden="true"
        style="text-decoration: none;font-family: Arial, Helvetica, sans-serif;font-size: 20px;padding-top:2px;">Menu</span>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-nav-list tabindex="1">
      <a mat-list-item i18n [routerLink]="" (click)="goHome()" (keyup.enter)="goHome()">Home</a>
      <span *ngFor="let item of commonService.menuItems">
        <a mat-list-item [routerLink]="" (click)="item.show=(item.show?false:true)">
          <mat-icon class="menu-button" [ngClass]="{'rotated' : item.show==true}">expand_more</mat-icon>
          {{item.name}}
        </a>
        <span *ngIf="item.show==true">
          <a *ngFor="let sub of item.submenu" mat-list-item routerLinkActive="active-list-item" [routerLink]=""
            (click)="navigateToApp(item, sub)" (keyup.enter)="navigateToApp(item, sub)"
            class="second_level">{{sub.name}}</a>
        </span>
      </span>
      <a mat-list-item i18n [routerLink]="" *ngIf="commonService.isPageEnabled('LOGIN.QuicksightDashboard')" 
        (click)="goDashboard()" (keyup.enter)="goDashboard()">Dashboard</a>
      <a mat-list-item i18n href="javascript: void(0);" (click)="commonService.logout()"
        (keyup.enter)="commonService.logout()">Logout
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <br>
    &nbsp;&nbsp;&nbsp;&nbsp;<img src="assets/img/parsons_logo.png" style="width:138px;height:29px;padding-right: 10px;"
      alt="" />
  </mat-sidenav>

  <!-- the tool bar -->
  <!-- style=" overflow: hidden;" -->
  <mat-sidenav-content>
    <div class="toolbar-container">
      <!-- Menu and BackToHome buttons -->
      <span class="toolbar-item menuHome">
        <span *ngIf="(isHandset$ | async) || true" class="menuHomeMenuTxt">
          <div>&nbsp;&nbsp;&nbsp;&nbsp;Menu</div>
        </span>
        <span><br>
          <button matTooltip="Menu" *ngIf="(isHandset$ | async) || true" type="button" class="menuHomeMenu"
            mat-icon-button (click)="drawer.toggle()" onclick="this.blur()" aria-label="Expandable Menu">
            <mat-icon [inline]="true">menu</mat-icon>
          </button>
        </span>
      </span>
      <button id="MainScreen.Home" class="homeBtn" (click)="goHome()" matTooltip="Back to home page" aria-label="Back to home page">
        <img style="max-width:90%" src="assets/img/DriveON_Portal_En.png" alt="Ontario DriveON" *ngIf="!switchLocale || switchLocale.includes('fr')" />
        <img style="max-width:90%" src="assets/img/DriveON_Portal_Fr.png" alt="Ontario DriveON" *ngIf="switchLocale && switchLocale.includes('en')" />
      </button>
      <span class="vl"></span>
      <span class="toolbar-item header_text width4075">
        {{txtVic}}{{appName}}{{txtPortal}}
      </span>
      <!--Version + Logout -->
      <span class="toolbar-item versionLogout">
        <br>
        <span>
          <img src="assets/img/parsons_logo.png" style="width:138px;height:29px;padding-right: 10px;" alt="Parsons Logo" />
        </span>
        <br>
        <!--Environment + version-->
        <span id="banner_env"
          style="float: right; color:#50bf34; font-family: Arial, Helvetica, sans-serif; text-decoration: none; font-size: 12px;padding-right: 10px;">
          {{env}} - {{version}}
          <br><br>
          <a i18n href="#" (click)="commonService.help($event)">Help </a>&nbsp;&nbsp;
          <span *ngIf="env != 'Production'">
            <a href="#" (click)="switch()">{{switchLanguage}}</a>&nbsp;&nbsp;
          </span>
          <a i18n href="javascript: void(0);" (click)="commonService.logout()" (keyup.enter)="commonService.logout()">
            Logout
          </a>
        </span>
      </span>
    </div>
    <div id="main" tabindex="0">
      <div id="inner">
        <router-outlet>
          <!-- Scroll Menu -->
          <div *ngIf="isShowScrollMenu === true">
            <div class="scrollmenu">
              <span *ngIf="!(isHandset$ | async) || true">
                <button style="height:200px !important;width:200px;"
                  *ngFor="let item of commonService.topMenuItems | slice:0:sliceCount" mat-button
                  routerLink="{{item.link}}" routerLinkActive="active-list-item"
                  style="font-size: 14px; font-weight: bold;height: 40px;">
                  {{item.name}}
                </button>
              </span>
            </div>
          </div>
          <br>
          <div [style.display]="commonService.isPageEnabled('AMT.RealtimeTriggerPopup')?'inline':'none'" *ngIf="showTrigger" style="max-height: 300px !important;overflow: auto !important;"><real-time-trigger-popup></real-time-trigger-popup></div>
          <br/>
        </router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
